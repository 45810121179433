import { useState, Children, cloneElement, useEffect } from 'react'
import useSound from 'use-sound'
import get from 'lodash/get'

import { useSmoothTransition } from '../../hooks/useSmoothTransition'


// CONSTANTS //
const __min__ = 0
const __max__ = 0.45

export const SmoothSoundWrapper = ({
  url,
  onClick = () => {},
  ready = true,
  disabled,
  children }) => {

  const [isPlaying, setIsPlaying] = useState(false)
  const [autoPlay, setAutoPlay] = useState(false)

  const volume = isPlaying ? __max__ : __min__
  const [floatVol, invalidVol] = useSmoothTransition({
    value: volume,
    callback: (val) => parseFloat(val).toFixed(2)
  })

  const [play, { duration, pause }] = useSound(url, {
    volume: floatVol,
    onend: () => {
      setAutoPlay(true)
    },
    html5: true
  })

  const isSoundReady = ready && !disabled && !!duration

  useEffect(() => {
    if (!isSoundReady || invalidVol) return

    if (floatVol <= __min__ && !isPlaying) {
      pause()
      setIsPlaying(false)
    }
    // eslint-disable-next-line
  }, [isPlaying, invalidVol])

  useEffect(() => {
    if (autoPlay) {
      play()
      setAutoPlay(false)
    }
    // eslint-disable-next-line
  }, [autoPlay])

  return Children.map(children, child => {
    const ready = get(child, 'props.ready', true)
    const currentClick = get(child, 'props.onClick', () => {})

    if (!isSoundReady || !ready) {
      const el = cloneElement(child, {
        disabled: true,
        ready: false,
        isPlaying: false,
        controls: true
      })

      return el
    }

    const compoundClick = (e) => {
      !isPlaying && !invalidVol && play()
      setIsPlaying(prev => !prev)
      currentClick(e)
      onClick(e)
    }

    return cloneElement(child, {
      onClick: compoundClick,
      isPlaying,
      controls: true
    })
  })
}

export const ClickSoundWrapper = ({
  url,
  oddUrl,
  volume = 0.15,
  ready = true,
  disabled = false,
  children }) => {

  const [play, { duration }] = useSound(url, { volume })
  const [oddPlay, { duration: oddDuration }] = useSound(oddUrl, { volume })
  const [count, setCount] = useState(0)
  const isOdd = !!(count % 2)

  const isSoundReady = ready && !disabled && !!duration && !!oddDuration

  return Children.map(children, child => {
    const ready = get(child, 'props.ready', true)
    const currentClick = get(child, 'props.onClick', () => {})

    if (!isSoundReady || !ready) {
      const el = cloneElement(child, {
        disabled: true,
        ready: false,
        controls: true
      })

      return el
    }

    const compoundClick = (e) => {
      setCount(prev => prev + 1)
      isOdd ? oddPlay() : play()
      currentClick(e)
    }

    return cloneElement(child, {
      onClick: compoundClick,
      controls: true
    })
  })
}
