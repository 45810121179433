import { useModeProps } from "../../Providers/ModeProps";
import { useScrollPercentage } from "../../hooks/useScrollPercentage";
import { useDeviceProps } from "../../Providers/DeviceProps";

const skyBlurValue = 8;

export const Sky = () => {
  const { isMobile } = useDeviceProps();
  const { parallax } = useModeProps();
  const { perc } = useScrollPercentage(parallax);

  const factor = (perc * skyBlurValue) / 100;

  return (
    <>
      <div
        className="star"
        style={
          !isMobile
            ? {
                filter: `blur(${Math.abs(factor)}px)`,
                transition: "filter .5s ease-in-out",
              }
            : {}
        }
      ></div>
      <div
        className="star-s"
        style={
          !isMobile
            ? {
                filter: `blur(${Math.abs(factor)}px)`,
                transition: "filter .5s ease-in-out",
              }
            : {}
        }
      ></div>
    </>
  );
};
