import { ParallaxLayer } from "@react-spring/parallax";
import { animated } from "react-spring";
import { SubtractedImg as NonAnimatedSubImg } from "../../components/SubtractedImage";
import { Sky } from "../../components/Sky";

import "./style.scss";

const SubtractedImg = animated(NonAnimatedSubImg);

export const BodyStructure = ({ pages = 1, mode = "day", opacity = 1 }) => {
  // const [_blurValues, setBlurValues] = useState(blurInitialValues)
  // const { focus } = useModeProps()
  // const { isTop } = useScrollPercentage(parallax)

  // const blurValues = useMemo(() => isTop ? blurInitialValues : _blurValues, [isTop ,blurInitialValues, _blurValues])

  const position = "absolute";
  const width = "100%";

  // useEffect(() => {
  //   // const factor = (focus * blurInitialValues.sky) / 100
  //   console.log(focus)
  //   // setBlurValues()
  // }, [focus])

  const Flower = () => {
    return (
      <SubtractedImg
        alt="flower"
        name={`${mode}-1`}
        style={{
          opacity,
          position,
          width: "35%",
        }}
        fixedPositionMult={0.9}
        dinPositionMult={1}
      />
    );
  };

  // const FlowerLand = () => {
  //   return <SubtractedImg
  //       alt="flower-land"
  //       name={`${mode}-2`}
  //       style={{opacity, position, width: '40%'}}
  //       // fixedPositionMult={1.08}
  //       // dinPositionMult={0.7}
  //     />
  // }

  const TreeLand = () => {
    return (
      <SubtractedImg
        alt="tree-land"
        name={`${mode}-3-v3`}
        style={{ opacity, position, width }}
        fixedPositionMult={0.9}
        dinPositionMult={1}
      />
    );
  };

  const MainLand = () => {
    return (
      <SubtractedImg
        alt="main-land"
        name={`${mode}-4-v2`}
        style={{ opacity, position, width }}
        dinPositionMult={0.9}
        useChildren
      >
        <div
          className="container"
          style={{
            opacity,
            position,
            width,
            height: ".3vw",
          }}
        >
          <div className="bird-container bird-container--one">
            <div
              className={`brightness-150 bird bird--one bird--${mode}`}
            ></div>
          </div>
        </div>
      </SubtractedImg>
    );
  };

  const MainSky = () => {
    return (
      <SubtractedImg
        alt="main-sky"
        name={`${mode}-8-v2`}
        style={{ opacity, position, width }}
      />
    );
  };

  const FrontSky = () => {
    return (
      <SubtractedImg
        alt="front-sky"
        name={`${mode}-5-v2`}
        style={{ opacity, position, width }}
        dinPositionMult={1.09}
      />
    );
  };

  const Cloud2 = () => {
    return (
      <SubtractedImg
        alt="cloud-2"
        name={`${mode}-6.2`}
        style={{ opacity, position, width: "25%", marginLeft: "55%" }}
        dinPositionMult={6}
      />
    );
  };

  const Cloud5 = () => {
    return (
      <SubtractedImg
        alt="cloud-5"
        name={`${mode}-6.5.1`}
        style={{
          opacity,
          position,
          width: "30%",
        }}
        dinPositionMult={3.4}
      />
    );
  };

  const Moon = () => {
    return (
      <SubtractedImg
        alt="moon"
        name={`${mode}-9`}
        style={{
          opacity,
          position,
          width: "12%",
          marginLeft: "80%",
        }}
        dinPositionMult={10}
        fixedPositionMult={6}
      />
    );
  };

  const Planet = () => {
    return (
      <SubtractedImg
        alt="planet"
        name={`${mode}-7-v2`}
        style={{
          opacity,
          position,
          width: "65%",
          marginLeft: "15%",
        }}
        dinPositionMult={2.8}
        fixedPositionMult={2.8}
      />
    );
  };

  return (
    <>
      <ParallaxLayer
        offset={0}
        className="background"
        factor={pages}
        style={{
          opacity,
          backgroundColor: mode === "day" ? `#38b9fa` : "#132e4f",
        }}
      >
        {mode === "night" && <Sky />}
      </ParallaxLayer>

      {/* STARS */}
      <ParallaxLayer offset={pages + 0.2} speed={0.3}>
        <Moon />
      </ParallaxLayer>
      <ParallaxLayer offset={pages + 0.1} speed={0.2}>
        <Planet />
      </ParallaxLayer>

      {/* SKY */}
      <ParallaxLayer offset={pages} speed={0.14}>
        <MainSky />
      </ParallaxLayer>
      <ParallaxLayer offset={pages + 0.13} speed={-0.02}>
        <FrontSky />
      </ParallaxLayer>
      <ParallaxLayer offset={pages + 0.13} speed={-0.12}>
        <Cloud2 />
      </ParallaxLayer>
      <ParallaxLayer offset={pages + 0.13} speed={-0.01}>
        <Cloud5 />
      </ParallaxLayer>

      {/* LAND */}
      <ParallaxLayer offset={pages + 0.1} speed={0.04}>
        <MainLand />
      </ParallaxLayer>
      <ParallaxLayer offset={pages + 0.05} speed={0.1}>
        <TreeLand />
      </ParallaxLayer>
      {/* <ParallaxLayer
        offset={pages + 0.05}
        speed={0.1}>
          <FlowerLand />
      </ParallaxLayer> */}
      <ParallaxLayer offset={pages + 0.1} speed={0.05}>
        <Flower />
      </ParallaxLayer>
    </>
  );
};
