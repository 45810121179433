const PUBLIC_URL = process.env.PUBLIC_URL

export const getSound = (name, _type = 'mp3') => {
  return `${PUBLIC_URL}/assets/sounds/${name}.${_type}`
}

export const getAllSoundsList = () => {
  const names = [
    'sound-v2'
  ]

  const sounds = []
  for (let i of names) {
    sounds.push([getSound(`${ i }`), 'sound'])
  }

  return sounds
}
