import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useTabs } from "react-headless-tabs";
import { useMultipleOutsideClick } from "../hooks/useOutsideClick";

export const SkillOpenedContext = createContext({});
SkillOpenedContext.displayName = "SkillOpenedContext";

const nextPosition = {
  closed: "opening",
  opening: "opened",
  opened: "closing",
  closing: "closed",
};

export const SkillOpenedProvider = ({ children, ...props }) => {
  const [status, setStatus] = useState("closed");
  const [canLoad, setCanLoad] = useState(false);
  const [canClick, setCanClick] = useState(true);
  const [refs, setRefs] = useState([]);
  const addRef = useCallback((ref) => setRefs((p) => [...p, ref]), []);
  const [selectedTab, setSelectedTab] = useTabs(["skills", "timeline"]);

  const closed = status === "closed";
  const opening = status === "opening";
  const closing = status === "closing";
  const opened = status === "opened";

  const updatePos = useCallback(
    (isFinal = false) => {
      setStatus(nextPosition[status]);
      if (isFinal) {
        setCanClick(true);
      }
    },
    [status]
  );

  const closeIfOpened = useCallback(() => {
    if (opened) updatePos(true);
  }, [opened, updatePos]);

  useMultipleOutsideClick(
    refs,
    () => {
      closeIfOpened();
    },
    [refs, status, canClick]
  );

  const toggleOpened = useCallback(() => {
    if (opening || closing) return;
    updatePos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!canLoad && opening) setCanLoad(true);
  }, [canLoad, opening, setCanLoad]);

  useEffect(() => {
    if (!(opening || closing) || !canClick) return;

    setCanClick(false);
    setTimeout(
      () => {
        updatePos(true);
      },
      opening ? 800 : 1400
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opening, closing]);

  return (
    <SkillOpenedContext.Provider
      value={{
        opened,
        toggleOpened,
        closed,
        status,
        setStatus,
        canLoad,
        setCanLoad,
        opening,
        closing,
        canClick,
        setCanClick,
        updatePos,
        closeIfOpened,
        refs,
        addRef,
        selectedTab,
        setSelectedTab,
      }}
      {...props}
    >
      {children}
    </SkillOpenedContext.Provider>
  );
};

export const useSkillOpenedPermission = () => {
  const context = useContext(SkillOpenedContext);
  if (!context) {
    throw new Error(
      "useSkillOpenedPermission must be used within a SkillOpenedProvider"
    );
  }

  return context;
};
