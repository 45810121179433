import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

import './style.scss'
export const VolumeSwitch = ({
  onClick = () => {},
  style,
  className,
  disabled,
  isPlaying,
  initial = false
}) => {

  const [on, seton] = useState(initial)

  const toogleOn = (e) => {
    seton(previous => !previous)
    onClick(e)
  }

  // useEffect(() => {
  //   seton(isPlaying)
  // }, [isPlaying])

  const properties = {
    on: {
      line1: {
        x1: 20,
        y1: 4,
        x2: 20,
        y2: 20
      },
      line2: {
        x1: 16,
        y1: 9,
        x2: 16,
        y2: 15
      },
    },
    off: {
      line1: {
        x1: 23,
        y1: 9,
        x2: 17,
        y2: 15
      },
      line2: {
        x1: 17,
        y1: 9,
        x2: 23,
        y2: 15
      }
    }
  }

  const { line1, line2 } = properties[
    on ? 'on' : 'off'
  ]

  const line1Pops = useSpring({ ...line1 })
  const line2Pops = useSpring({ ...line2 })
  const opacity = useSpring(disabled ? { opacity: 0.3} : { opacity: 1})

  const Svg = <animated.svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="white"
    onClick={disabled ? () => {} : toogleOn}
    className={className}
    style={{
      ...style,
      cursor: disabled ? 'default' : 'pointer',
      ...opacity
    }}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    color="white">
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <animated.line {...line1Pops}></animated.line>
    <animated.line {...line2Pops}></animated.line>
  </animated.svg>

  return Svg
}
