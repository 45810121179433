import { useSkillOpenedPermission } from "../../Providers/SkillOpenedProps";

export const Phrase = () => {
  const { opened } = useSkillOpenedPermission();
  return (
    <div className="text-white">
      <h1 className="absolute top-[-10000vh] opacity-0">Willian Steinagel</h1>
      <h2 className="absolute top-[-10000vh] opacity-0">
        High skilled front-end engineer
      </h2>
      <h3 className="absolute top-[-10000vh] opacity-0">
        React expert; Web Performance expert; Senior React Engineer
      </h3>

      <div
        className="w-screen flex flex-col justify-center items-center px-4 transition-all duration-1000"
        style={{ opacity: opened ? "0.3" : "1" }}
      >
        <div className="flex flex-col items-center lg:items-end">
          <div className="flex flex-row">
            <p className="font-normal text-center lg:text-end text-3xl">
              "A problem well stated is a problem half solved"
            </p>
          </div>
          <p className="font-normal text-base">Charles Kettering</p>
        </div>
        <p className="font-normal text-sm mb-2"></p>
      </div>
    </div>
  );
};
