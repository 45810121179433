import React from "react";

import { SmoothSoundWrapper, ClickSoundWrapper } from "../../SoundWrapper";
import { getSound } from "../../../functions/sounds";
import { VolumeSwitch } from "../../VolumeSwitch";

import "./style.scss";

const soundOn = getSound("soundon", "wav");
const soundOff = getSound("soundoff-v2", "wav");

export const Player = ({ url, style, className }) => {
  return (
    <ClickSoundWrapper url={soundOn} oddUrl={soundOff}>
      <SmoothSoundWrapper url={url}>
        <VolumeSwitch style={style} className={className} />
      </SmoothSoundWrapper>
    </ClickSoundWrapper>
  );
};
