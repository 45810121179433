import { createContext, useContext, useEffect, useState } from "react";
import { useModeProps } from "./ModeProps";

export const MissionContext = createContext();

const initialMissions = [
  {
    id: 1,
    text: "Find the starts",
    completed: false,
  },
  {
    id: 2,
    text: "Find the bird",
    completed: false,
  },
  {
    id: 3,
    text: "Enjoy the music",
    completed: false,
  },
  {
    id: 4,
    text: "Find the skills",
    completed: false,
  },
  {
    id: 5,
    text: "Find the timeline",
    completed: false,
  },
];

export const MissionProvider = ({ children }) => {
  const { mode } = useModeProps();
  const [missions, setMissions] = useState(() => {
    const storedMissions = localStorage.getItem("missions");
    return storedMissions ? JSON.parse(storedMissions) : initialMissions;
  });

  useEffect(() => {
    localStorage.setItem("missions", JSON.stringify(missions));
  }, [missions]);

  useEffect(() => {
    if (mode === "night") {
      setMissions((prevMissions) =>
        prevMissions.map((mission) => {
          if (mission.id === 1) {
            return { ...mission, completed: true };
          }
          return mission;
        })
      );
    }
  }, [mode]);

  const onMissionComplete = (id) => {
    setMissions((prevMissions) =>
      prevMissions.map((mission) =>
        mission.id === id ? { ...mission, completed: true } : mission
      )
    );
  };

  return (
    <MissionContext.Provider value={{ missions, onMissionComplete }}>
      {children}
    </MissionContext.Provider>
  );
};

export const useMissions = () => {
  const context = useContext(MissionContext);
  if (!context) {
    throw new Error("useMissions must be used within a MissionProvider");
  }
  return context;
};
