import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { ModePropsProvider } from "./Providers/ModeProps";
import { DevicePropsProvider } from "./Providers/DeviceProps";
import { SkillOpenedProvider } from "./Providers/SkillOpenedProps";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ModePropsProvider>
      <DevicePropsProvider>
        <SkillOpenedProvider>
          <App />
        </SkillOpenedProvider>
      </DevicePropsProvider>
    </ModePropsProvider>
  </React.StrictMode>
);

// https://bit.ly/CRA-vitals
reportWebVitals();
