import { ModeSwitch } from "../../ModeSwitch";
import { useModeProps } from "../../../Providers/ModeProps";
import { useSkillOpenedPermission } from "../../../Providers/SkillOpenedProps";
import { getSound } from "../../../functions/sounds";
import { Player } from "../../compound/Player";

import "./style.scss";

const soundUrl = getSound("sound-v2");

export const ModeChanger = ({ className, style }) => {
  const { switchMode, mode } = useModeProps();
  const { opened } = useSkillOpenedPermission();

  return (
    <div className="flex flex-row gap-4">
      <ModeSwitch
        className={`${className} transition-all duration-1000`}
        style={{ ...style, opacity: opened ? "0.3" : "1" }}
        onClick={switchMode}
        defaultOn={mode === "day"}
      />
      <Player url={soundUrl} />
    </div>
  );
};
