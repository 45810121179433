import { useState } from 'react'
import { useSpring } from 'react-spring'

// CONSTANTS //
const __cb__ = x => x

export const useSmoothTransition = ({ value, interpolate = __cb__ }) => {
  const [smoothValue, setMoothValue] = useState(() => value)

  const isTransiting = smoothValue > value || smoothValue < value

  useSpring({
    value,
    onChange({ value: { value } }) {
      setMoothValue(interpolate(value))
    }
  })

  return [smoothValue, isTransiting]
}
