import { useState, useEffect } from "react";
import get from "lodash/get";
import { useWindowDimensions } from "./useWindowDimensions";
import { useLocalStorageState } from "./useLocalStorageState";
import { debounce } from "../functions/debounce";

const defaultProps = {
  threshold: 50,
  debounceTime: 1200,
};

export const useScrollPercentage = (
  scrollRef,
  accessProps = "current.current",
  { threshold = 50 } = defaultProps
) => {
  const { currentProportion } = useWindowDimensions();
  const [isBottom, setIsBottom] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [perc, setPerc] = useState(0);
  const [maxScroll, setMaxScroll] = useLocalStorageState("__maxScroll", 0);

  useEffect(() => {
    setMaxScroll(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProportion]);

  useEffect(() => {
    const calculate = (t, h) => (t / h) * 100;

    const asyncSet = debounce(async (scroll) => {
      setIsTop(scroll < threshold);
      setIsBottom(scroll > maxScroll - threshold);
      const perc = calculate(scroll, maxScroll);
      setPerc(perc > 100 ? 100 : perc);
    }, 100);

    const listenToScroll = () => {
      const scroll = get(scrollRef, accessProps, 0);
      if (scroll > maxScroll) setMaxScroll(scroll);
      asyncSet(scroll);
    };

    document.addEventListener("scroll", listenToScroll, true);
    return () => {
      document.removeEventListener("scroll", listenToScroll, true);
    };
  }, [accessProps, maxScroll, scrollRef, setMaxScroll, threshold]);

  return {
    isBottom,
    isTop,
    perc,
  };
};
